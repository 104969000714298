import React, {useMemo} from 'react';
import styled from "styled-components";
import {
  BasicDropdown,
  BREAKPOINTS,
  Button,
  COLORS,
  DropdownFilter,
  InputLabel,
  Size,
  States,
  SystemIcons,
  TextField,
  TooltipWrapper
} from "@laerdal/life-react-components";
import {useTranslation} from "react-i18next";
import {useController, useForm} from "react-hook-form";
import {useDynamicInputSize, useDynamicSize} from "../../../hooks/DynamicSize";
import {useNavigate, useSearchParams} from "react-router-dom";
import {useAppDispatch, useAppSelector} from "../../../store";
import {yupResolver} from "@hookform/resolvers/yup";
import {organizationSchema} from "../schema";
import {EXCLUDE_VAT_COUNTRIES, INCLUDE_FEDERAL_TAX_ID_COUNTRIES, INDUSTRY_OPTIONS} from "../config";
import {setOrganizationModel} from "../organization.slice";
import {navigateByUrl} from "../../../utilities/navigate";

const Page = styled.div`
  display: flex;
  flex-direction: column;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
`;

const Header = styled.h3`
  border-bottom: 1px solid ${COLORS.neutral_200};

  padding-bottom: 32px;
`;

const Group = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const GroupHeaderContainer = styled.div`
  display: flex;
  gap: 8px;
  flex-direction: row;
  align-items: center;

  & > div {
    display: flex;
  }
`;

const GroupHeader = styled.h6``;

const Actions = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;

  button {
    flex: 1;
  }

  ${BREAKPOINTS.MEDIUM} {
    flex-direction: row;
  }
`;

const InputWrapper = styled.div`
  input {
    width: 100%;
  }

  [role=listbox],
  [role=listbox] > div,
  [role=combobox],
  [role=combobox] > div {
    min-width: unset;
  }
`;

const Row = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  gap: 8px;

  ${InputWrapper} {
    width: 100%;
  }


  ${BREAKPOINTS.MEDIUM} {
    flex-direction: row;

    ${InputWrapper}:first-child:nth-last-child(2),
    ${InputWrapper}:first-child:nth-last-child(2) ~ ${InputWrapper},
    ${InputWrapper}:first-child:nth-last-child(3),
    ${InputWrapper}:first-child:nth-last-child(3) ~ ${InputWrapper} {
      width: calc(50% - 4px);
    }
  }

  ${BREAKPOINTS.LARGE} {
    ${InputWrapper}:first-child:nth-last-child(3),
    ${InputWrapper}:first-child:nth-last-child(3) ~ ${InputWrapper} {
      width: calc(33.33% - 4px);
    }
  }
`;


export const OrganizationDetailsStep = () => {
  const {t} = useTranslation('Organization');

  const inputSize = useDynamicInputSize();

  const dispatch = useAppDispatch();
  const data = useAppSelector(state => state.organization.value)
  const countries = useAppSelector(state => state.organization.countries)
  const cancelUrl = useAppSelector(state => state.organization.callbackUrl?.onCancel)

  const navigate = useNavigate();

  const {handleSubmit, control} = useForm({
    defaultValues: {...data},
    resolver: yupResolver(organizationSchema(t)),
    context: {countries} as any
  });

  const name = useController({name: 'name', control});
  const industry = useController({name: 'industry', control});

  const vat = useController({name: 'vatNumber', control});
  const taxId = useController({name: 'federalTaxId', control});
  const contact = useController({name: 'billingContactPerson', control});
  const email = useController({name: 'billingEmailAddress', control});

  const companyName = useController({name: 'address.companyName', control});
  const additionalName = useController({name: 'address.additionalName', control});
  const countryCode = useController({name: 'address.countryCode', control});
  const addressLine = useController({name: 'address.addressLine', control});
  const addressLineTwo = useController({name: 'address.addressLineTwo', control});
  const city = useController({name: 'address.city', control});
  const state = useController({name: 'address.state', control});
  const zipCode = useController({name: 'address.zipCode', control});
  const phoneNumber = useController({name: 'address.phoneNumber', control});

  const submit = handleSubmit((data) => {
    dispatch(setOrganizationModel(data));
    navigate('../confirm');
  });

  const industryOptions = INDUSTRY_OPTIONS(t);

  const countryOptions =
    useMemo(() =>
      countries.map((country) => ({
        displayLabel: country.name,
        value: country.codeAlpha2,
      })), [countries]
    );

  const country = countries.find((country) => country.codeAlpha2 === countryCode.field.value);

  const states =
    useMemo(() =>
      country?.states?.map((state) => ({
        displayLabel: state.name,
        value: state.code,
      })), [country]
    );

  return (
    <Page>
      <Wrapper>
        <Header>{t('Create your organization')}</Header>
        <Group>
          <GroupHeaderContainer>
            <GroupHeader>
              {t('Billing details')}
            </GroupHeader>
            <TooltipWrapper label={t('These billing details will be used to address your invoices.')!}
                            position={'top'}
                            align={'center'}>
              <SystemIcons.Information/>
            </TooltipWrapper>
          </GroupHeaderContainer>
          <Row>
            <InputWrapper>
              <InputLabel inputId={'name'} text={t('Organization name')}/>
              <TextField id={'name'}
                         size={inputSize}
                         {...name.field}
                         state={name.fieldState.invalid ? States.Invalid : undefined}
                         validationMessage={name.fieldState.invalid ? name.fieldState.error!.message! : undefined}
                         onChange={e => {
                           name.field.onChange(e);
                           companyName.field.onChange(e);
                         }}/>
            </InputWrapper>
          </Row>
          <Row>
            <InputWrapper>
              <InputLabel inputId={'country'} text={t('Country / Region')}/>
              <DropdownFilter id={'country'}
                              size={inputSize}
                              list={countryOptions}
                              multiple={false}
                              placeholder={t('Select...')!}
                              scrollable={true}
                              activeValidationMessage={countryCode.fieldState.invalid ? countryCode.fieldState.error!.message! : undefined}
                              name={countryCode.field.name}
                              ref={countryCode.field.ref}
                              onBlur={countryCode.field.onBlur}
                              value={countryCode.field.value}
                              disabled={countryCode.field.disabled}
                              onSelect={countryCode.field.onChange}/>
            </InputWrapper>
          </Row>
          <Row>
            {
              INCLUDE_FEDERAL_TAX_ID_COUNTRIES.includes(countryCode.field.value!) &&
              <InputWrapper>
                <InputLabel inputId={'taxId'} text={t('Federal Tax ID')}/>
                <TextField id={'taxId'}
                           size={inputSize}
                           state={taxId.fieldState.invalid ? States.Invalid : undefined}
                           validationMessage={taxId.fieldState.invalid ? taxId.fieldState.error!.message! : undefined}
                           {...taxId.field}/>
              </InputWrapper>
            }
            {
              !EXCLUDE_VAT_COUNTRIES.includes(countryCode.field.value!) &&
              <InputWrapper>
                <InputLabel inputId={'vat'} text={t('VAT number (if applicable)')}/>
                <TextField id={'vat'}
                           size={inputSize}
                           state={vat.fieldState.invalid ? States.Invalid : undefined}
                           validationMessage={vat.fieldState.invalid ? vat.fieldState.error!.message! : undefined}
                           {...vat.field}/>
              </InputWrapper>
            }
            <InputWrapper>
              <InputLabel inputId={'industry'} text={t('Industry (optional)')}/>
              <BasicDropdown id={'industry'}
                             size={inputSize}
                             list={industryOptions}
                             placeholder={t('Select...')!}
                             activeValidationMessage={industry.fieldState.invalid ? industry.fieldState.error!.message! : undefined}
                             ref={industry.field.ref}
                             onBlur={industry.field.onBlur}
                             value={`${industry.field.value}`}
                             disabled={industry.field.disabled}
                             onSelect={industry.field.onChange}/>
            </InputWrapper>
          </Row>
          <Row>
            <InputWrapper>
              <InputLabel inputId={'contact'} text={t('Billing representative')}/>
              <TextField id={'contact'}
                         state={contact.fieldState.invalid ? States.Invalid : undefined}
                         validationMessage={contact.fieldState.invalid ? contact.fieldState.error!.message! : undefined}
                         note={
                           !contact.fieldState.invalid
                             ? {
                               message: t('The name of a person who handles invoices.'),
                               icon: <></>
                             }
                             : undefined
                         }
                         size={inputSize}
                         {...contact.field}/>
            </InputWrapper>
            <InputWrapper>
              <InputLabel inputId={'email'} text={t('Billing email address')}/>
              <TextField id={'email'}
                         size={inputSize}
                         state={email.fieldState.invalid ? States.Invalid : undefined}
                         validationMessage={email.fieldState.invalid ? email.fieldState.error!.message! : undefined}
                         note={
                           !email.fieldState.invalid
                             ? {
                               message: t('Receipts and invoices will be sent to this email address.'),
                               icon: <></>
                             }
                             : undefined
                         }
                         {...email.field}/>
            </InputWrapper>
          </Row>
        </Group>

        <Group>
          <Row>
            <GroupHeader>
              {t('Address')}
            </GroupHeader>
          </Row>
          <Row>
            <InputWrapper>
              <InputLabel inputId={'company'} text={t('Company or billing name')}/>
              <TextField id={'company'}
                         readOnly={true}
                         size={inputSize}
                         {...companyName.field}/>
            </InputWrapper>
          </Row>
          <Row>
            <InputWrapper>
              <InputLabel inputId={'additional'} text={t('Additional names (optional)')}/>
              <TextField id={'additional'}
                         size={inputSize}
                         state={additionalName.fieldState.invalid ? States.Invalid : undefined}
                         validationMessage={additionalName.fieldState.invalid ? additionalName.fieldState.error!.message! : undefined}
                         {...additionalName.field}/>
            </InputWrapper>
          </Row>
          <Row>
            <InputWrapper>
              <InputLabel inputId={'addressLine'} text={t('Address line 1')}/>
              <TextField id={'addressLine'}
                         size={inputSize}
                         state={addressLine.fieldState.invalid ? States.Invalid : undefined}
                         validationMessage={addressLine.fieldState.invalid ? addressLine.fieldState.error!.message! : undefined}
                         note={
                           !addressLine.fieldState.invalid
                             ? {
                               message: t('Add street number first, if applicable'),
                               icon: <></>
                             }
                             : undefined
                         }
                         {...addressLine.field}/>
            </InputWrapper>
          </Row>
          <Row>
            <InputWrapper>
              <InputLabel inputId={'addressLineTwo'} text={t('Address line 2')}/>
              <TextField id={'addressLineTwo'}
                         size={inputSize}
                         state={addressLineTwo.fieldState.invalid ? States.Invalid : undefined}
                         validationMessage={addressLineTwo.fieldState.invalid ? addressLineTwo.fieldState.error!.message! : undefined}
                         note={
                           !addressLineTwo.fieldState.invalid
                             ? {
                               message: t('Building, department, floor, etc.'),
                               icon: <></>
                             }
                             : undefined
                         }
                         {...addressLineTwo.field}/>
            </InputWrapper>
          </Row>
          <Row>
            <InputWrapper>
              <InputLabel inputId={'city'} text={t('City')}/>
              <TextField id={'city'}
                         size={inputSize}
                         state={city.fieldState.invalid ? States.Invalid : undefined}
                         validationMessage={city.fieldState.invalid ? city.fieldState.error!.message! : undefined}
                         {...city.field}/>
            </InputWrapper>
            {
              !!states?.length &&
              <InputWrapper>
                <InputLabel inputId={'state'} text={t('State / Province')}/>
                <DropdownFilter id={'state'}
                                size={inputSize}
                                list={states}
                                multiple={false}
                                placeholder={t('Select...')!}
                                scrollable={true}
                                activeValidationMessage={state.fieldState.invalid ? state.fieldState.error!.message! : undefined}
                                name={state.field.name}
                                ref={state.field.ref}
                                onBlur={state.field.onBlur}
                                value={state.field.value}
                                disabled={state.field.disabled}
                                onSelect={state.field.onChange}/>
              </InputWrapper>
            }
            <InputWrapper>
              <InputLabel inputId={'zipCode'} text={t('Zip / Postal Code')}/>
              <TextField id={'zipCode'}
                         size={inputSize}
                         state={zipCode.fieldState.invalid ? States.Invalid : undefined}
                         validationMessage={zipCode.fieldState.invalid ? zipCode.fieldState.error!.message! : undefined}
                         {...zipCode.field}/>
            </InputWrapper>
          </Row>
          <Row>
            <InputWrapper>
              <InputLabel inputId={'phone'} text={t('Telephone and area code')}/>
              <TextField id={'phone'}
                         size={inputSize}
                         placeholder={'(___) ___-____'}
                         state={phoneNumber.fieldState.invalid ? States.Invalid : undefined}
                         validationMessage={phoneNumber.fieldState.invalid ? phoneNumber.fieldState.error!.message! : undefined}
                         note={
                           !phoneNumber.fieldState.invalid
                             ? {
                               message: t('In case we need to contact you about your order(s).'),
                               icon: <></>
                             }
                             : undefined
                         }
                         {...phoneNumber.field}/>
            </InputWrapper>
          </Row>
        </Group>

        <Actions>
          <Button variant='primary'
                  onClick={submit}
                  size={Size.Large}>
            {t('Continue')}
          </Button>
          {
            !!cancelUrl &&
            <Button variant='tertiary'
                    onClick={() => navigateByUrl(cancelUrl, navigate)}
                    size={Size.Large}>
              {t('Cancel')}
            </Button>
          }
        </Actions>
      </Wrapper>
    </Page>
  )
}