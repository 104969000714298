import React, {useContext} from 'react';
import {Navigate, Route, Routes, useSearchParams} from 'react-router-dom';
import {Helmet} from 'react-helmet';

import {createGlobalStyle} from 'styled-components';

import {ConfigContext} from './contexts/ConfigContext';

import {GlobalLoadingPage, globalStyles} from '@laerdal/life-react-components';

import {Layout} from './components/Layout';
import {
  FedRampSSO,
  LegacyLogin,
  ProxyOidc,
  ProxySaml,
  ConsentOidc,
  Error,
  ResetPassword,
  ForgotPassword,
  VerifyEmail,
  Redirect,
  Login,
  Logout,
  NotFound
} from './pages';
import useScript from './hooks/useScript';
import {SSOCallback} from "./pages/sso/SSOCallback";
import {SSOInitiate} from "./pages/sso/SSOInitiate";
import {useTranslation} from "react-i18next";
import {Invitation} from "./pages/screens/invitation/Invitation";
import {SignInOnBehalfPage} from "./pages/sign-in-on-behalf/SignInOnBehalfPage";
import {TokenLoginPage} from "./pages/token-login/TokenLoginPage";
import {CreateOrganizationPage} from "./pages/organization/CreateOrganizationPage";
import {OrganizationDetailsStep} from "./pages/organization/steps/OrganizationDetailsStep";
import {OrganizationConfirmStep} from "./pages/organization/steps/OrganizationConfirmStep";
import {Provider} from "react-redux";
import store from "./store";
import {AuthenticatedRoute} from "./components/AuthenticatedRoute";

const GlobalStyle = createGlobalStyle`
  ${globalStyles}
  #root {
    min-height: 100vh;
    height: unset;
  }
`;

const GlobalStyleProxy: any = GlobalStyle;

const App = () => {

  const {i18n} = useTranslation();
  const [search, updateSearch] = useSearchParams();

  const config = useContext(ConfigContext);
  const [scriptLoaded] = useScript(config?.apiKey, true);
  const [localizationInitialized, setLocalizationInitialized] = React.useState(false);

  React.useEffect(() => {
    i18n.on('initialized', () => {
      setLocalizationInitialized(true);

      const lang = search.get('gig_lng');
      search.delete('gig_lng');
      if (lang) {
        updateSearch(search);
        i18n.changeLanguage(lang);
      }
    });
  }, [])

  React.useEffect(() => {
    if (process.env.REACT_APP_SMARTLING_ENABLE_TRACKING?.toString() == 'true') {
      let interval = setInterval(() => {
        if (window.SmartlingContextTracker) {
          window.SmartlingContextTracker.init({
            orgId: process.env.REACT_APP_SMARTLING_ORGANIZATION_ID,
          });
          clearInterval(interval);
        }
      }, 100)
    }
  }, []);

  const loading = !scriptLoaded || !localizationInitialized;

  return (
    <Provider store={store}>
      <Layout>
        <Helmet>
          <title>Laerdal Medical</title>
          {
            process.env.REACT_APP_SMARTLING_ENABLE_TRACKING?.toString() == 'true' &&
            <script type="text/javascript" src="//d2c7xlmseob604.cloudfront.net/tracker.min.js"></script>
          }
        </Helmet>

        <GlobalStyleProxy/>
        {
          // @ts-ignore
          loading && <GlobalLoadingPage/>
        }
        {
          !loading &&
          <Routes>
            <Route path="login">
              <Route path="" element={<Login/>}/>
              <Route path="on-behalf/:id" element={<SignInOnBehalfPage/>}/>
              <Route path="token" element={<TokenLoginPage/>}/>
            </Route>

            <Route path="forgotpassword" element={<ForgotPassword/>}/>
            <Route path="resetpassword" element={<ResetPassword/>}/>
            <Route path="verifyemail" element={<VerifyEmail/>}/>

            <Route path="join/:type/:code" element={<Invitation/>}/>

            <Route path="sso">
              <Route path="initiate" element={<SSOInitiate/>}/>
              <Route path="callback" element={<SSOCallback/>}/>
              <Route path="" element={<FedRampSSO/>}/>
            </Route>

            <Route path="oidc">
              <Route path="login" element={<LegacyLogin/>}/>
              <Route path="consent" element={<ConsentOidc/>}/>
              <Route path="proxy" element={<ProxyOidc/>}/>
            </Route>

            <Route path="saml">
              <Route path="login" element={<LegacyLogin/>}/>
              <Route path="proxy" element={<ProxySaml/>}/>
            </Route>

            <Route path="organization" element={<AuthenticatedRoute/>}>
              <Route path="create" element={<CreateOrganizationPage/>}>
                <Route path="details" element={<OrganizationDetailsStep/>}/>
                <Route path="confirm" element={<OrganizationConfirmStep/>}/>
              </Route>
            </Route>

            <Route path="logout" element={<Logout/>}/>
            <Route path="redirect" element={<Redirect/>}/>
            <Route path="error" element={<Error/>}/>
            <Route path="*" element={<NotFound/>}/>
          </Routes>
        }
      </Layout>
    </Provider>
  );
};

export default App;
