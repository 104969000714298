import {TFunction} from "i18next";
import {Industry} from "../../models/industry";

export const INCLUDE_FEDERAL_TAX_ID_COUNTRIES = ['IT'];
export const EXCLUDE_VAT_COUNTRIES = ['DE'];

export const INDUSTRY_OPTIONS = (t: TFunction) => [
  {displayLabel: t('Healthcare'), value: Industry.Healthcare.toString()},
  {displayLabel: t('Educational Provider'), value: Industry.EducationalProvider.toString()},
  {displayLabel: t('Emergency Medical Service'), value: Industry.EmergencyMedicalService.toString()},
  {displayLabel: t('Gov/Federal and Military'), value: Industry.GovFederalAndMilitary.toString()},
  {displayLabel: t('Other'), value: Industry.Other.toString()},
];