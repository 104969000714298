import {createSlice} from "@reduxjs/toolkit";
import {Country,} from "../../models/country";
import {Industry} from "../../models/industry";

export interface CreateOrganizationAddress {
  companyName?: string;
  additionalName?: string;

  addressLine?: string;
  addressLineTwo?: string;
  zipCode?: string;
  city?: string;
  state?: string;
  countryCode?: string;

  phoneNumber?: string;
  email?: string;

}

export interface CreateOrganizationModel {
  name: string;
  industry?: Industry;
  vatNumber: string;
  federalTaxId: string;
  billingContactPerson: string;
  billingEmailAddress: string;
  address: CreateOrganizationAddress
}

export interface OrganizationState {
  loading: boolean;
  countries: Country[];
  value: CreateOrganizationModel;

  callbackUrl?: {
    onSuccess?: string;
    onCancel?: string;
  }
}

export const organizationSlice = createSlice({
  name: 'organization',
  initialState: {
    loading: true,
    countries: [],
    value: {
      name: '',
      vatNumber: '',
      federalTaxId: '',
      billingContactPerson: '',
      billingEmailAddress: '',
      address: {
        companyName: '',
        additionalName: '',
        countryCode: '',
        addressLine: '',
        addressLineTwo: '',
        city: '',
        state: '',
        zipCode: '',
        phoneNumber: '',
        email: ''
      }
    }
  } as OrganizationState,
  reducers: {
    setOrganizationCountries: (state, action) => {
      state.countries = action.payload;
      state.loading = false;
    },
    setOrganizationModel: (state, action) => {
      state.value = {
        ...state.value,
        ...action.payload
      };
    },
    setOrganizationCallbackUrl: (state, action) => {
      state.callbackUrl = action.payload;
    }
  }
});

export const {
  setOrganizationCountries,
  setOrganizationModel,
  setOrganizationCallbackUrl
} = organizationSlice.actions;

export default organizationSlice.reducer;