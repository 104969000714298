import React, {useEffect} from 'react';
import {GlobalLoadingPage} from "@laerdal/life-react-components";
import {Navigate, Outlet, useLocation, useNavigate, useSearchParams} from "react-router-dom";


export const AuthenticatedRoute = () => {

  const [authorized, setAuthorized] = React.useState<boolean | undefined>(undefined);
  const location = useLocation();

  useEffect(() => {
    window.gigya.accounts.session.verify({
      callback: (response) => {
        setAuthorized(response.errorCode === 0);
      }
    })
  }, []);

  return authorized === undefined
    ? <GlobalLoadingPage/>
    : !authorized
      ? <Navigate to={`/login?returnUrl=${location.pathname}${location.search}`} replace={true}/>
      : <Outlet/>;
}